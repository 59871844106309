import React from 'react';
import './style.scss';
import { SlideImg, AppBoxes1, AppBoxes2 } from 'components';
import gg_play from 'assets/imgs/qr_google_play.png';
import app_store from 'assets/imgs/qr_app_store.png';
import download_app from 'assets/imgs/download_app.svg';
import Header from 'pages/Header';

const LandingPage = () => {
  return (
    <div className="landing">
      <Header />
      <section className="container">
        <h1>Trải nghiệm ngay</h1>

        <div className="landing__app">
          <AppBoxes1 />
        </div>
        <div className="landing__app2">
          <AppBoxes2 />
        </div>
      </section>

      <section className="container">
        <h1>Ứng dụng</h1>
        <div className="landing__slide">
          <SlideImg />
        </div>
      </section>

      <section className="container landing__download-app mt-4">
        <div className="landing__app-text">
          <h1>Tải về</h1>
          <div className="landing__description">
            Digilife dành cho thiết bị di động
          </div>

          <div className="landing__qr-wrapper">
            <img
              alt="qr-code"
              src={gg_play}
              className="img-QRcode"
            />
            <img
              alt="qr-code"
              src={app_store}
              className="img-QRcode"
            />
          </div>
        </div>
        <img alt="download-app" src={download_app} className="landing__img-download" />
      </section>
    </div>
  );
};

export default LandingPage;
