import React, { useState } from 'react';
import './style.scss';
import Carousel from 'react-multi-carousel';
import ip1 from 'assets/imgs/iphone_1.png';
import ip2 from 'assets/imgs/iphone_2.png';
import ip3 from 'assets/imgs/iphone_3.png';
import ip4 from 'assets/imgs/iphone_4.png';
import ip5 from 'assets/imgs/iphone_5.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  }
};

const SlideImg = () => {
  const [index, setIndex] = useState(0);

  return (
    <div className="carousel">
      <Carousel
        draggable={false}
        responsive={responsive}
        infinite={true}
        autoPlay
        additionalTransfrom={1}
        autoPlaySpeed={5000}
        transitionDuration={0}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        afterChange={(previousSlide, { currentSlide }) => {
          setIndex(currentSlide)
        }}
      >
        <img alt="img" src={ip1} className={`carousel-img ${index === 8 && 'transform'}
          ${(index === 9)  && 'transform-mobile'}`} />
        <img alt="img" src={ip2} className={`carousel-img ${(index === 9)  && 'transform'}
          ${(index === 0 || index === 5)  && 'transform-mobile'}`} />
        <img alt="img" src={ip3} className={`carousel-img ${(index === 5 || index === 0) && 'transform'}
          ${(index === 6)  && 'transform-mobile'}`} />
        <img alt="img" src={ip4} className={`carousel-img ${index === 6 && 'transform'}
          ${(index === 7)  && 'transform-mobile'}`} />
        <img alt="img" src={ip5} className={`carousel-img ${index === 7 && 'transform'}
          ${(index === 8)  && 'transform-mobile'}`} />
      </Carousel>
    </div>
  );
};

export default SlideImg;
