import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { store } from 'store';
import { HOME_PAGE, POLICY_WEBVIEW, PRIVATE_WEBVIEW, TERM_WEBVIEW, PERSONAL_POLICY_WEBVIEW } from 'global/routes';
import { Provider } from 'react-redux';

import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import PolicyWebview from 'pages/Policy/PolicyWebview';
import PrivateWebview from 'pages/Private/PrivateWebview';
import TermWebview from 'pages/Term/TermWebview';
import MainPage from 'pages/MainPage/MainPage';
import PersonalPolicyWebview from "./pages/PersonalPolicy/PersonalPolicyWebview";


function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return (null);
}

const App = () => (
  <Router>
    <ScrollToTop />
    <Provider store={store}>
      <div className="app-wrapper">
        <Switch>
          <Route
            path={POLICY_WEBVIEW}
            exact
            component={PolicyWebview}
          />
          <Route
            path={PRIVATE_WEBVIEW}
            exact
            component={PrivateWebview}
          />
          <Route
            path={TERM_WEBVIEW}
            exact
            component={TermWebview}
          />
          <Route
              path={PERSONAL_POLICY_WEBVIEW}
              exact
              component={PersonalPolicyWebview}
          />
          <Route
            path={HOME_PAGE}
            component={MainPage}
          />
        </Switch>
      </div>
    </Provider>
  </Router>
);

export default App;
