import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HOME_PAGE, POLICY, PRIVATE, TERM, DOWNLOAD, PERSONAL_POLICY } from 'global/routes';
import Policy from 'pages/Policy/Policy';
import Private from 'pages/Private/Private';
import Term from 'pages/Term/Term';
import LandingPage from 'pages/LandingPage/LandingPage';
import Version from 'pages/Version/Version';
import Footer from 'pages/Footer';
import DownloadStore from 'pages/Download/DownloadStore';
import PersonalPolicy from "../PersonalPolicy/PersonalPolicy";

const MainPage = () => {
  return (
    <div className="site-layout-background p-6 min-h-full" >
      <Switch>
        <Route
          path={HOME_PAGE}
          exact
          component={LandingPage}
        />
        <Route
          path={POLICY}
          exact
          component={Policy}
        />
        <Route
          path={PRIVATE}
          exact
          component={Private}
        />
        <Route
          path={TERM}
          exact
          component={Term}
        />
        <Route
            path={PERSONAL_POLICY}
            exact
            component={PersonalPolicy}
        />
        <Route
          path="/version"
          exact
          component={Version}
        />
        <Route
          path={DOWNLOAD}
          component={DownloadStore}
        />
      </Switch>
      <Footer />
      <div className="landing__end">
        <div className="container">
          <div className="landing__end--content">
            <span>Bản Quyền thuộc Tập đoàn Bưu chính Viễn thông Việt Nam</span>
            <a href='http://online.gov.vn/Home/WebDetails/72089' rel="noopener noreferrer" target="_blank">
              <img alt='' title='' src='http://online.gov.vn/Content/EndUser/LogoCCDVSaleNoti/logoSaleNoti.png' /></a>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MainPage);