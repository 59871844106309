import React from 'react';

const Version = () => (
  <div className="container mt-5">
    <h1>
      <span>Version: </span>
      <span>08/11/2021 v1</span>
    </h1>
  </div>
)

export default React.memo(Version);
