import React, { useState, useEffect } from 'react';

const DownloadStore = () => {
    let userAgent = window.navigator.userAgent
    if (userAgent.includes("Android")) {
        window.location.replace("https://play.google.com/store/apps/details?id=com.vnpt.media&hl=vi");
    } else if (userAgent.includes("iPhone") || userAgent.includes("iOS")) {
        window.location.replace("https://apps.apple.com/us/app/id1516951370");
    } else {
        //cant be sure about device, so redirect to home page
        window.location.replace("/");
    }
}

export default React.memo(DownloadStore);