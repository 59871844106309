import React from 'react';

const PrivateWebview = () => {
  return (
    <div className="term">
      <div className="term__content">
        <h3>Chính sách bảo mật</h3>
        <div className="term__body mt-5">
          <p>Đối với chúng tôi, việc bảo vệ thông tin riêng tư của bạn là cực kỳ quan trọng. Theo đó, Tập đoàn Bưu chính Viễn thông Việt Nam (VNPT), (sau đây gọi là “Chúng tôi”) cung cấp Chính sách Bảo mật này (sau đây gọi là “Chính sách”) để giải thích các chính sách về thông tin chúng tôi có thể thu thập và cách chúng tôi dự định quản lý, bảo vệ, sử dụng và tiết lộ thông tin mà chúng tôi nhận được khi bạn sử dụng ứng dụng Ứng dụng và các ứng dụng thành phần (sau đây gọi là “Ứng dụng”).</p>
          <p>Chính sách bảo mật này áp dụng cho thông tin và dữ liệu được chúng tôi thu thập hoặc nhận được thông qua việc bạn sử dụng Ứng dụng. Chúng tôi tuyên bố rõ rằng, chúng tôi không liên quan và không chịu trách nhiệm đối với bất kỳ thông tin, dữ liệu, trang web, sản phẩm và dịch vụ nào được cung cấp bởi bất kỳ tổ chức nào không có quan hệ với chúng tôi. Bất kỳ liên kết nào đến các trang web đó không thể hiện sự tài trợ của chúng tôi hay quan hệ đối tác của chúng tôi với các tổ chức đó.</p>
          <p>Kế hoạch của chúng tôi là chỉ phân phối Ứng dụng này tại Việt Nam.</p>
          <p>Nếu bạn có bất cứ thắc mắc nào về việc chúng tôi xử lý dữ liệu cá nhân của bạn, vui lòng liên hệ với chúng tôi theo địa chỉ vanphong@vnpt.vn</p>
          <h4>Chính sách bảo vệ thông tin cá nhân khách hàng</h4>
          <p>Mọi sự thu thập thông tin và sử dụng thông tin của Khách hàng đều được thông qua ý kiến của chính Khách hàng đó trước khi Khách hàng tiến hành các giao dịch cụ thể. Việc bảo vệ thông tin cá nhân Khách hàng được thực hiện theo đúng nguyên tắc sau:</p>
          <h4>1. Mục đích thu thập</h4>
          <p>
            – Thực hiện các giao dịch theo các đơn đặt hàng của Khách hàng, giới thiệu về dịch vụ, tính năng mới của DigiLife, chăm sóc Khách hàng<br />
            – Tính giá, cước sử dụng<br />
            – Quản lý việc đăng tải, bình luận của khách hàng về giao dịch yêu cầu thực hiện<br />
            – Giải quyết các thắc mắc hay khiếu nại phát sinh khi Khách hàng sử dụng dịch vụ DigiLife.<br />
            – Ngăn chặn những hoạt động vi phạm pháp luật tại DigiLife.
          </p>
          <h4>2. Nguyên tắc cơ bản của chúng tôi</h4>
          <p>Về cơ bản, chúng tôi không thu thập hay yêu cầu bạn cung cấp thông tin cá nhân thông qua việc sử dụng Ứng dụng trừ khi chúng tôi thực sự cần thông tin đó theo yêu cầu của luật hiện hành và/ hoặc các hướng dẫn hoặc yêu cầu bắt buộc của các cơ quan chức năng. Ngay cả khi chúng tôi thu thập và lưu trữ thông tin cá nhân của bạn, chúng tôi không chia sẻ thông tin cá nhân đó với bất kỳ ai ngoại trừ việc tuân thủ các luật và / hoặc quy định hiện hành, hoặc cần thiết để bảo vệ các quyền hợp pháp của chúng tôi và các mục đích thích hợp khác được nêu trong tài liệu này.</p>
          <h4>3. Phạm vi sử dụng thông tin</h4>
          <p>
            Chúng tôi sẽ thu thập và xử lý các thông tin sau khi bạn sử dụng Ứng dụng:<br />
            <p>
              - Chỉ sử dụng các thông tin được khách hàng đăng ký hoặc cập nhật trên website/ ứng dụng Ứng dụng, không sử dụng các thông tin do khách hàng đưa trên các phương tiện, công cụ khác.<br />
              - Dữ liệu về kiểu và kiểu thiết bị đầu cuối, phiên bản và nền tảng hệ điều hành của bạn.<br />
              - Dữ liệu về các hoạt động của bạn trên Ứng dụng, chẳng hạn như phiên bản Ứng dụng, điểm trò chơi của bạn, tiến trình, thời gian sử dụng Ứng dụng, lịch sử duyệt web, v.v.<br />
              - Tên và địa chỉ email của bạn khi bạn yêu cầu bộ phận hỗ trợ khách hàng của chúng tôi.<br />
              - Khi cần thiết, chúng tôi có thể sử dụng những thông tin này để liên hệ trực tiếp với khách hàng để thông báo về: thông tin về khuyến mãi và dịch vụ mới, liên lạc và giải quyết với khách hàng những trường hợp đặc biệt…<br />
              - Không sử dụng số điện thoại của khách hàng ngoài mục đích xác nhận và liên hệ có liên quan đến dịch vụ mà khách hàng đăng ký sử dụng của công ty.<br />
              Chúng tôi không cố ý thu thập hoặc trích xuất thông tin cá nhân của khách hàng dưới 16 tuổi. Nếu bạn dưới 16 tuổi, vui lòng không gửi bất kỳ thông tin nào về bạn cho chúng tôi, bao gồm nhưng không giới hạn tên, địa chỉ, số điện thoại hoặc địa chỉ email của bạn.<br />
              Khi biết rằng chúng tôi đã thu thập thông tin cá nhân của trẻ em dưới 16 tuổi, chúng tôi sẽ xóa thông tin đó ngay lập tức. Nếu bạn tin rằng chúng tôi có thể có bất kỳ thông tin nào về hoặc về trẻ em dưới 16 tuổi, vui lòng thông báo cho chúng tôi.
            </p>
          </p>
          <h4>4. Cơ sở để sử dụng thông tin của bạn</h4>
          <p>
            Thông tin của bạn là cần thiết để chúng tôi thực hiện các dịch vụ và nghĩa vụ của mình bao gồm cả việc vận hành Ứng dụng. Bạn có thể kiểm soát và rút lại sự đồng ý của mình bất cứ lúc nào.<br />
            Nếu không, việc sử dụng thông tin của bạn vẫn cần thiết vì những lợi ích hợp pháp sau đây của chúng tôi hoặc của những người khác:<br />
            (1) vận hành và cải thiện hoạt động kinh doanh của chúng tôi, bao gồm cả doanh thu liên quan đến quảng cáo,<br />
            (2) thực hiện, quản lý và cập nhật Ứng dụng và mục tiêu tối ưu hóa kinh doanh của chúng tôi<br />
            (3) phân tích cách ứng dụng của chúng tôi được người dùng sử dụng
          </p>
          <h4>5. Cách chúng tôi sử dụng thông tin của bạn</h4>
          <p>
            Chúng tôi sẽ chuyển giao, lưu trữ và sử dụng thông tin được liệt kê trong Điều 3 cho các mục đích sau.<br />
            (1) cung cấp Ứng dụng và nếu cần, tạo tài khoản của bạn.<br />
            (2) liên quan đến các hoạt động của bạn trong Ứng dụng, cải thiện và phát triển các dịch vụ và ứng dụng mới của chúng tôi.<br />
            (3) bảo trì các dịch vụ của chúng tôi để đảm bảo không có việc sử dụng không phù hợp, trái phép hoặc bất hợp pháp.<br />
            (4) với sở thích của bạn, đề xuất các quảng cáo phù hợp hơn cho bạn.<br />
            (5) gửi các thông báo liên quan đến Ứng dụng.<br />
            (6) trao đổi với bạn về Ứng dụng thông qua bộ phận hỗ trợ khách hàng của chúng tôi.
          </p>

          <h4>6. Thời gian lưu trữ:</h4>
          05 năm
          <h4>7. Những người, tổ chức có thể tiếp cận thông tin đã thu thập bao gồm</h4>
          – Đơn vị chủ quản Ứng dụng.<br />
          – Đơn vị viễn thông khi thực hiện trừ giá cước sử dụng.<br />
          – Cơ quan nhà nước có thẩm quyền khi có yêu cầu cụ thể.


          <h4>8. Địa chỉ của đơn vị thu thập và quản lý thông tin</h4>
          <p>
            TỔNG CÔNG TY TRUYỀN THÔNG<br />
            Địa chỉ: Số 57A, Huỳnh Thúc Kháng, P.Láng Hạ, Q.Đống Đa, TP.Hà Nội, Việt Nam<br />
            Điện thoại: 0243.7722728<br />
            Fax: 0243.7722733<br />
            Email: Ktht.ptkdvas @vnpt.vn
          </p>
          <h4>9. Phương thức và công cụ để người tiêu dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình trên hệ thống thương mại điện tử của đơn vị thu thập thông tin</h4>
          <p>
            – Người dùng có thể tiếp cận thông tin cá nhân (gói cước sử dụng, nội dung sử dụng…) bằng cách truy cập vào website/ ứng dụng dịch vụ, vào mục tra cứu, nhập số điện thoại để biết được khách hàng có đăng ký dịch vụ hay không.<br />
            – Về phần chỉnh sử dữ liệu cá nhân: khách hàng không chính sử được số điện thoại vì khi khách hàng đăng ký dịch vụ thì hệ thống sẽ nhận biết số điện thoại của khách hàng.
          </p>
          <h4>10. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông báo</h4>
          <p>
            Khi phát hiện thông tin cá nhân (cụ thể số điện thoại) của mình bị sử dụng sai mục đích hoặc phạm vi, người dùng có quyền gọi đến Tổng đài chăm sóc khách hàng: 19001268 hoặc gửi email khiếu nại đến Ktht.ptkdvas@vnpt.vn với các thông tin, chứng cứ liên quan tới việc này. Công ty cam kết sẽ phản hồi ngay lập tức trong vòng 24 tiếng để cùng khách hàng thống nhất phương án giải quyết.
          </p>
          <h4>11. Cam kết bảo mật thông tin cá nhân của Khách hàng</h4>
          <p>
            - Bạn hiểu và đồng ý rằng chúng tôi có thể chia sẻ thông tin được liệt kê trong Điều 3 (ngoại trừ thông tin nhân thân của bạn), với các bên thứ ba hoặc cơ quan công quyền cho các mục đích nêu trên. Trừ khi luật hiện hành yêu cầu, chúng tôi sẽ không thông báo cho bạn về việc tiết lộ này nếu có.<br />
            - Thông tin cá nhân của khách hàng trên Ứng dụng được Ứng dụng cam kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá nhân của Ứng dụng.Việc thu thập và sử dụng thông tin của mỗi khách hàng chỉ được thực hiện khi có sự đồng ý của khách hàng đó trừ những trường hợp pháp luật có quy định khác;<br />
            - Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ 3 nào về thông tin cá nhân của Khách hàng khi không có sự cho phép đồng ý từ khách hàng;<br />
            - Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân khách hàng, Ứng dụng sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho Khách hàng được biết;<br />
            - Tuy nhiên do hạn chế về mặt kỹ thuật, không một dữ liệu nào có thể được truyền trên đường truyền internet mà có thể được bảo mật 100%. Do vậy, Ứng dụng không thể đưa ra một cam kết chắc chắn rằng thông tin Khách hàng cung cấp cho Ứng dụng sẽ được bảo mật một cách tuyệt đối an toàn, và Ứng dụng không thể chịu trách nhiệm trong trường hợp có sự truy cập trái phép thông tin cá nhân của Khách hàng, như các trường hợp khách hàng tự ý chia sẻ thông tin với người khác.
          </p>
          <h4>12. Lưu trữ dữ liệu</h4>
          <p>Chúng tôi lưu giữ thông tin cá nhân của bạn nếu nó cần thiết để chúng tôi có thể cung cấp Ứng dụng cho bạn, trừ khi luật hiện hành yêu cầu chúng tôi xóa hoặc nếu chúng tôi chấp nhận yêu cầu xóa thông tin đó của bạn. Chúng tôi cũng sẽ lưu giữ và sử dụng thông tin cá nhân của bạn nếu cần thiết để đáp ứng các nghĩa vụ pháp lý của chúng tôi, giải quyết tranh chấp và thực thi Điều khoản dịch vụ.

          </p>
          <h4>13. Thông tin được thu thập tự động</h4>
          <p>
            Khi người dùng sử dụng dịch vụ của chúng tôi, trong một số chức năng, ứng dụng có thực hiện gửi số điện thoại mà người dùng chọn lên VNPT Media (https://api.vnptpay.vn/ ...).<br />
            Việc gửi số điện thoại này chỉ cho một mục đích duy nhất là kiểm tra số điện thoại đã chọn có liên kết với tài khoản VNPT Pay nào không, nhằm giúp người dùng có thể sử dụng chức năng một cách dễ dàng hơn.<br />
            Chúng tôi cam kết không sử dụng thông tin này cho bất kì mục đích nào khác và không chia sẻ thông tin này cho bất kỳ bên thứ 3. Đồng thời, quá trình truyền dữ liệu lên được thực hiện với giao thức “https” đã được mã hóa và an toàn.</p>
          <h4>14. Các thay đổi đối với chính sách này</h4>
          <p>Chúng tôi có thể cập nhật và thay đổi chính sách này theo quyết định riêng của mình. Chúng tôi khuyên bạn nên kiểm tra định kỳ để xem các cập nhật hoặc thay đổi nào đối với chính sách này. Xin lưu ý rằng nếu chúng tôi cần thay đổi Chính sách này do các yêu cầu pháp lý, thì chính sách mới sẽ có hiệu lực ngay lập tức hoặc theo yêu cầu.</p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PrivateWebview);
