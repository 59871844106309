import React from 'react';
import './style.scss';
import logo from 'assets/imgs/footer_logo.svg';
import footer_dia_chi from 'assets/imgs/footer_dia_chi.svg';
import footer_dien_thoai from 'assets/imgs/footer_dien_thoai.svg';
import footer_fax from 'assets/imgs/footer_fax.svg';
import footer_email from 'assets/imgs/footer_email.svg';
import footer_giay_chung_nhan from 'assets/imgs/footer_giay_chung_nhan.svg';
import footer_people from 'assets/imgs/footer_people.svg';
import { Link } from 'react-router-dom';
import { POLICY, PRIVATE, TERM, PERSONAL_POLICY } from 'global/routes';

const Footer = () => {

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__top">
          <img src={logo} alt="logo" className="footer__logo" />
          <div>
            <Link to={POLICY}>Chính sách dịch vụ</Link>
            <Link to={PRIVATE}>Chính sách bảo mật</Link>
            <Link to={TERM}>Điều khoản sử dụng</Link>
            <Link to={PERSONAL_POLICY}>Chính sách bảo vệ dữ liệu cá nhân</Link>
          </div>
        </div>
        <hr />

        <h1>
          TẬP ĐOÀN BƯU CHÍNH VIỄN THÔNG VIỆT NAM (VNPT)
        </h1>

        <div className="footer__item-wrapper">
          <div className="footer__item">
            <img src={footer_dia_chi} alt="logo" className="footer__item-img" />
            <div>
              <div className="footer__title">Địa chỉ</div>
              <div className="footer__des">Tòa nhà VNPT, số 57 Phố Huỳnh Thúc Kháng, Phường Láng Hạ, Quận Đống Đa, Thành phố Hà Nội, Việt Nam</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_giay_chung_nhan} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">Giấy chứng nhận ĐKKD số</div>
              <div className="footer__des">0100684378 do Sở Kế hoạch và Đầu tư TP.Hà Nội cấp lần đầu ngày 17/8/2010. Đăng ký thay đổi lần 4 ngày 20/03/2017</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_dien_thoai} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">ĐIỆN THOẠI</div>
              <div className="footer__des">024 3774 1091</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_people} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">Chịu trách nhiệm nội dung</div>
              <div className="footer__des">Ông Vũ Trường Giang - Tổng Giám đốc Tổng công ty Truyền thông VNPT Media - Chi nhánh Tập đoàn Bưu chính viễn thông Việt Nam</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_fax} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">FAX</div>
              <div className="footer__des">024 3774 1093</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_email} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">EMAIL</div>
              <div className="footer__des">Vanphong@vnpt.vn</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
