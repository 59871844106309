import cui from './cui/cui.js';
import KCSModal from './Modal/KCSModal.js';
import InputText from './InputText/InputText.js';
import ProgressTurn from './ProgressTurn/ProgressTurn.js';
import ScrollTop from './ScrollTop/ScrollTop.js';
import SlideImg from './SlideImg/SlideImg.js';
import { AppBoxes1, AppBoxes2 } from './AppBoxes/AppBoxes.js';

export {
  cui,
  KCSModal,
  InputText,
  ProgressTurn,
  ScrollTop,
  SlideImg,
  AppBoxes1,
  AppBoxes2,
};
