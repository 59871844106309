import React, { useState, useEffect } from 'react';
import './style.scss';
import ic_film from 'assets/imgs/ic_film.svg';
import ic_clip from 'assets/imgs/ic_clip.svg';
import ic_music from 'assets/imgs/ic_music.svg';
import ic_new from 'assets/imgs/ic_new.svg';
import ic_health from 'assets/imgs/ic_health.svg';
import ic_data from 'assets/imgs/ic_data.svg';
import ic_mytv from 'assets/imgs/ic_mytv.png';
import ic_myvnpt from 'assets/imgs/ic_myvnpt.png';

const LIST_APP1 = [
  {
    title: 'Digi Movie',
    des: 'Phim nào mới ra, phim nào hot, phim nào hay, phim độc quyền, phim hành động hay tâm lý tình cảm đều có trong kho hơn 1500 phim của DigiMovie. Digi Movie tích hợp thêm tính năng review phim cho phép bạn chia sẻ những cảm nhận của mình cho mọi người.',
    img: ic_film,
    linkAndroid: 'https://play.google.com/store/apps/details?id=com.vnpt.media.digimovie',
    linkIOS: 'https://apps.apple.com/vn/app/digimovie/id1527037767',
  },
  {
    title: 'Digi Today',
    des: 'Mọi tin tức bạn quan tâm từ tin giải trí, chính trị, kinh tế, showbiz, tin thể thao mới nhất, sự kiện hot, bình luận chuyên sâu đều được cập nhật ngay lập tức đến bạn. Đặc biệt, Digi Today với tính năng tích hợp tin tức địa phương sẽ cho bạn 1 kho thông tin về bất cứ nơi đâu bạn đặt chân đến.',
    img: ic_new,
    linkAndroid: 'https://play.google.com/store/apps/details?id=soft.media.vnpt.vn.digitoday',
    linkIOS: 'https://apps.apple.com/vn/app/digitoday/id1518541651',
  },
  {
    title: 'Digi health',
    des: 'Digi Health sẽ giúp toàn bộ hồ sơ sức khoẻ của gia đình được lưu trữ đơn giản, tiện tra cứu khi đi khám chữa bệnh ở bất kỳ đâu, hỗ trợ tư vấn sức khoẻ với đội ngũ bác sỹ giỏi, hỗ trợ đặt lịch khám tiện lợi. Hàng ngày, Bạn có thể thoải mái tập Gym, Aerobic, Jumba, Dance Sport…  “chuẩn bài” với đội ngũ PT online mà không cần đến phòng tập.',
    img: ic_health,
    linkAndroid: 'https://play.google.com/store/apps/details?id=com.vnpt.media.digihealth',
    linkIOS: ' https://apps.apple.com/vn/app/digihealth/id1527040278',
  },
  {
    title: 'Digi clip',
    des: 'Xem clip hài, clip chế chất lượng cao với các tính năng xem chung, những nội dung “độc và lạ”, chia sẻ clip, tự upload clip khiến cho DigiClip giúp bạn có những giây phút cười thả ga, giải tỏa những căng thẳng trong cuộc sống.',
    img: ic_clip,
    linkAndroid: 'https://play.google.com/store/apps/details?id=com.vnpt.media.digiclip',
    linkIOS: 'https://apps.apple.com/vn/app/digiclip/id1526610336',
  },
  {
    title: 'Digi music',
    des: 'Kho nhạc hot, nhạc mới, từ Sơn Tùng đến Đen Vâu, Us, UK, EDM Underground, nhạc Việt Nam và quốc tế đều sẵn sàng để cùng bạn phiêu trong âm nhạc bất kỳ lúc nào. Đặc biệt, Digi Music với chuẩn chất lượng vượt trội và các tính năng ấn tượng như chia sẻ, xem chung sẽ mang đến những trải nghiệm trên cả tuyệt vời.',
    img: ic_music,
    linkAndroid: 'https://play.google.com/store/apps/details?id=com.vnptmedia.digimusic ',
    linkIOS: 'https://apps.apple.com/vn/app/digimusic/id1520501989',
  },
  {
    title: 'Digi cloud',
    des: 'Tính năng lưu trữ file, bảo mật dữ liệu, quản lý các tài liệu ngay trên điện thoại. Đặc biệt DigiCloud dành tặng miễn phí 5Gb cho tất cả mọi người.',
    img: ic_data,
    linkAndroid: '#',
    linkIOS: '#',
  },
]

const LIST_APP2 = [
  {
    title: 'MyTV',
    des: 'Những trận bóng đỉnh cao, những bộ phim truyền hình đang phát sóng, tivishow hấp dẫn sẽ không bao giờ bị bỏ lỡ bởi bạn đã có trên điện thoại của mình tính năng MyTV – Mang trọn thế giới truyền hình trong tay bạn. Đặc biệt hơn, DigiLife miễn phí toàn bộ data khi sử dụng ứng dụng khiến bạn có thể có những trải nghiệm ấn tượng với các bộ phim, ca nhạc, giải trí truyền hình ở bất kỳ đâu.',
    img: ic_mytv,
    linkAndroid: 'https://play.google.com/store/apps/details?id=vn.mytvnet.mobileb2c',
    linkIOS: 'https://apps.apple.com/vn/app/mytv-multiscreen/id1505256562?mt=8',
  },
  {
    title: 'MyVNPT',
    des: 'Dành riêng cho các khách hàng của VNPT, tính năng selfcare MyVNPT cho phép khách hàng tra cứu thông tin về các dịch vụ mình đang sử dụng, các chương trình khuyến mại, đăng ký/huỷ dịch vụ, nạp thẻ/ thanh toán hoá đơn đa kênh v.v., cung cấp dữ liệu về các tài khoản lưu lượng, tài khoản cước theo thời gian thực giúp khách hàng kiểm soát cước phí và chi tiêu di động.',
    img: ic_myvnpt,
    linkAndroid: 'https://play.google.com/store/apps/details?id=com.vnp.myvinaphone',
    linkIOS: 'https://apps.apple.com/us/app/my-vnpt/id1017188399',
  },
]

const Boxes = ({ title, des, img, linkStore }) => (
  <div className="app" key={title}>
    <a href={linkStore} target="_blank" rel="noopener noreferrer">
      <img alt="icon" src={img} />
    </a>
    <div className="app__title">{title}</div>
    <div className="app__des">{des}</div>
  </div>
);


const BoxesWrapper = ({ appList }) => {
  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      setIsIOS(true);
    }
  }, []); 

  return (
    <>
      {appList.map((item, key) => (
        <Boxes
          key={key}
          title={item.title}
          des={item.des}
          img={item.img}
          linkStore={isIOS ? item.linkIOS : item.linkAndroid}
        />
      ))}
    </>
  )
}

export const AppBoxes1 = () => (
  <BoxesWrapper appList={LIST_APP1} />
)

export const AppBoxes2 = () => (
  <BoxesWrapper appList={LIST_APP2} />
)
