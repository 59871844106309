import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const Term = () => {
  return (
    <div className="term">
      <div className="container">
        <div className="bread-crumb">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to="/">Digilife</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Điều khoản sử dụng</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="term__content">
          <h3>Điều khoản sử dụng</h3>
          <div className="term__body mt-5">
            <p>
              Các điều khoản sử dụng này được qui định trên website/wapsite/app Dịch vụ DigiLife (DigiLife)<br />
              – Sau đây viết tắt là DV DigiLife, và sẽ cấu thành đầy đủ thoả thuận giữa các khách hàng và DV DigiLife liên quan tới hoạt động trên website/wapsite/app DV DigiLife. Nếu bất kỳ điều khoản nào bị vô hiệu hoá bởi quyết định của cơ quan có thẩm quyền, thì sự vô hiệu lực của điều khoản đó sẽ không ảnh hưởng tới tính hiệu lực của các điều khoản khác được nêu ra tại website/wapsite/app này. DV DigiLife có quyền bổ sung, sửa đổi nội dung điều khoản sử dụng này vào bất kỳ thời điểm nào mà không cần phải thông báo trước, và khách hàng phải có trách nhiệm theo dõi các qui định chung tại website/ ứng dụng thường xuyên để nhận biết được những sự thay đổi này. Việc cá nhân tiếp tục sử dụng website/ ứng dụng sau khi DV DigiLife bổ sung các nội dung trong các điều khoản và qui định thể hiện rằng bạn đã đọc và đồng ý tuân theo những sửa đổi đó.
            </p>
            <h4>1. Quy định về tài khoản và thông tin chủ tài khoản</h4>
            <p>Để sử dụng các dịch vụ, người sử dụng cần phải đăng ký tài khoản và các thông tin về chủ sở hữu tài khoản</p>
            <h5>1.1. Quy định tên tài khoản:</h5>
            <p>
              – Tên tài khoản gồm các ký tự bằng chữ cái không dấu, chữ số, dấu gạch dưới.<br />
              – Khi tạo lập tài khoản điền đầy đủ các thông tin được yêu cầu khi thực hiện đăng ký.<br />
              – Việc tạo lập tài khoản cần tuân thủ các nguyên tắc sau:<br />
              + Không được đặt tên và tạo biểu tượng liên quan đến các danh nhân, tên của các vị lãnh đạo Đảng và Nhà Nước.<br />
              + Không được đặt tên và tạo biểu tượng có nội dung phản động, bài xích tôn giáo, khiêu dâm, bạo lực, đi ngược lại thuần phong mỹ tục, truyền thống và văn hóa Việt Nam.<br />
              + Không được đặt tên và tạo biểu tượng có nội dung xúc phạm, khích bác đến người khác dưới bất kỳ hình thức nào.<br />
              + Không được đặt tên và tạo biểu tượng có nội dung phá rối hay làm mất uy tín của các dịch vụ do DV DigiLife cung cấp.<br />
              + Không được đặt tên và tạo biểu tượng tên kẻ xấu (trùm khủng bố, phát xít…)
            </p>
            <h5>1.2 Thông tin chủ tài khoản:</h5>
            <p>
              – Khi đăng ký, người dùng cần cung cấp đầy đủ, chính xác các thông tin được yêu cầu trong bản đăng ký. Việc cung cấp thông tin chính xác giúp chúng tôi bảo vệ quyền lợi chính đáng của chủ tài khoản. Chúng tôi chỉ hỗ trợ giải quyết tranh chấp mà không chịu trách nhiệm đối với bất kỳ tổn thất nào của khách hàng.<br />
              – Chủ tài khoản phải tự bảo quản tài khoản, mật khẩu, email và các thông tin cần thiết phòng khi có sự cố xảy ra. Chúng tôi không chịu trách nhiệm trước bất kỳ tổn thất nào của bạn do quá trình sử dụng dịch vụ gây ra.<br />
              – Email của tài khoản là email được sử dụng duy nhất trong hệ thống. Chủ tài khoản tự chịu trách nhiệm về bảo mật email và sử dụng email để đăng ký trong hệ thống
            </p>
            <h4>2. Yêu cầu đối với chủ tài khoản</h4>
            <p>
              Chủ tài khoản phải tuân thủ các quy định trong thỏa thuận này cũng như các hành vi dưới đây:<br />
              – Không được xâm phạm an ninh hoặc có hành vi tìm cách xâm phạm an ninh hệ thống máy chủ của DV DigiLife.<br />
              – Không được mạo nhận là các thành viên khác (người quản trị hệ thống, chuyên viên hỗ trợ,… ) của DV DigiLife.<br />
              – Không phát tán và truyền bá thông tin bất hợp pháp, lừa gạt, bôi nhọ, sỉ nhục, tục tĩu, khiêu dâm, xúc phạm, đe dọa, lăng mạ, thù hận, kích động… hoặc trái với chuẩn mực đạo đức chung của xã hội.<br />
              – Không gửi thông tin quảng cáo, thư dây chuyền hay bất kỳ dạng liên lạc có mục đích thương mại (trừ trường hợp được DV DigiLife cho phép bằng văn bản).<br />
              – Không được gửi hoặc truyền bất kỳ thông tin hoặc phần mềm nào có chứa bất kỳ loại virus, trojan, bọ hay các thành phần nguy hại nào đến sự an toàn của hệ thống dịch vụ.<br />
              – Không được sử dụng những từ ngữ, lời lẽ, dấu hiệu, ký tự không lành mạnh, tục tĩu, phỉ báng, xúc phạm,… nhân viên quản trị hệ thống dịch vụ, người dùng và các tổ chức khác.<br />
              – Không được gây rối, cản trở, phá hoại các sự kiện được tổ chức trên DV DigiLife.
            </p>
            <h4>3. Rủi ro cá nhân khi truy cập</h4>
            Khi truy cập vào website/ ứng dụng này bạn chấp thuận và đồng ý với việc có thể gặp một số rủi ro và đồng ý rằng DV DigiLife cũng như các bên liên kết chịu trách nhiệm xây dựng website/ ứng dụng này sẽ không chịu trách nhiệm pháp lý cho bất cứ thiệt hại nào đối với với bạn dù là trực tiếp, đặc biệt, ngẫu nhiên, hậu quả để lại, bị phạt hay bất kỳ mất mát, phí tổn hoặc chi phí có thể phát sinh trực tiếp hay gián tiếp qua việc sử dụng hoặc chuyển tải dữ liệu từ website/ ứng dụng này, bao gồm nhưng không giới hạn bởi tất cả những ảnh hưởng do virus, tác động hoặc không tác động đến hệ thống máy vi tính, đường dây điện thoại, phá hỏng ổ cứng hay các phần mềm chương trình, các lỗi kỹ thuật khác gây cản trở hoặc trì hoãn việc truyền tải qua máy vi tính hoặc kết nối mạng
            <h4>4. Ngưng cấp quyền sử dụng</h4>
            Các thành viên tham gia DV DigiLife sẽ bị ngưng cấp quyền sử dụng dịch vụ mà không được báo trước nếu vi phạm một trong những điều sau:<br />
            – Đăng tải những nội dung mang tính khiêu dâm, đồi trụy, tục tĩu, phỉ báng, hăm doạ người khác, vi phạm pháp luật hoặc dẫn tới hành vi phạm pháp.<br />
            – Spam dưới mọi hình thức tại website/ ứng dụng DV DigiLife.<br />
            – Vi phạm các quy định khác của DV DigiLife.<br />
            DV DigiLife sẽ không chịu trách nhiệm hay có nghĩa vụ gì đối với các nội dung đó, và sẽ hợp tác hết mình với cơ quan luật pháp hay tòa án khi có yêu cầu công bố những hành vi đăng tải thông tin và dữ liệu trái phép này
            <h4>5. Nội dung dịch vụ</h4>
            DV DigiLife là tổ hợp các sản phẩm - dịch vụ - tiện ích cực kỳ hấp dẫn đa nền tảng, đa ứng dụng cho phép khách hàng truy cập và thưởng thức hàng trăm nghìn nội dung giải trí, hữu ích được cập nhật định kỳ từ các đơn vị/cá nhân cung cấp có uy tín tại Việt Nam, được giao lưu tương tác với các thành viên khác trên 1 ứng dụng duy nhất.<br />
            Mọi thành viên, khi sử dụng một trong các chức năng sau của DV DigiLife, cần ý thức rằng những hành động của mình cần phải hoàn toàn phù hợp với luật dân sự và luật bản quyền hiện hành và chịu trách nhiệm trước pháp luật đối với nội dung mình đưa lên.<br />
            – Sử dụng DV DigiLife để trải nghiệm kho nội dung hấp dẫn, đặc sắc có bản quyền.<br />
            – Sử dụng DV DigiLife để chia sẻ các nội dung khi tương tác với người dùng khác.<br />
            – Sử dụng tài nguyên trên hệ thống của người dùng
            <h4>6. Chính sách giao dịch, giao nhận, bảo hành, đổi trả và hoàn tiền</h4>
            <h5>6.1. Chính sách giao dịch</h5>
            Khách hàng có thể đăng ký gói cước/mua hoặc sử dụng nội dung thông qua các kênh cung cấp của dịch vụ được mô tả chi tiết tại mục Giới thiệu , Hỗ trợ hoặc Trang cá nhân.
            <h5>6.2. Chính sách thanh toán</h5>
            Khi thực hiện đăng ký gói cước/mua hoặc tải nội dung hoặc thực hiện các giao dịch vụ tính phí khác, DV DigiLife sẽ căn cứ trên các giao dịch mà khách hàng thực hiện để tiến hành trừ tiền trên từng giao dịch.<br />
            Đối với thuê bao trả trước, DV DigiLife sẽ kết nối với nhà mạng để xác định khoản tiền cần trừ trực tiếp ngay tại thời điểm khách hàng yêu cầu lệnh.<br />
            Đối với thuê bao trả sau, hệ thống sẽ ghi nhận số tiền phải trả trên tài khoản điện thoại và được ghi vào hóa đơn cước của tháng tương ứng.<br />
            DV DigiLife sử dụng các dịch vụ, hệ thống thanh toán tích hợp nhiều tính năng thông minh, chế độ bảo mật cao để đảm bảo thông tin cá nhân, đảm bảo an toàn thanh toán của khách hàng. DV DigiLife sẽ không tính tiền cho đến khi giao dịch được hoàn tất. Việc thanh toán dựa trên cơ chế trừ tiền của mạng viễn thông nên luôn đảm bảo độ chính xác cao nhất.
            <h5>6.3. Chính sách bảo hành, đổi trả và hoàn tiền</h5>
            Ngay khi hệ thống nhận được lệnh tải/đăng ký hợp lệ, hệ thống sẽ ngay lập tức ghi nhận chính sách thuê bao được hưởng trên dịch vụ hoặc gửi nội dung khách hàng đã mua về thiết bị của khách hàng.<br />
            DV DigiLife không áp dụng chính sách bảo hành, đổi trả sản phẩm khi nội dung đã được chuyển đến thiết bị của Khách hàng thành công.<br />
            Tất cả các giao dịch đã nhận được thông báo thành công đều không được hoàn trả phí từ hệ thống trong mọi trường hợp.<br />
            Trong trường hợp giao dịch lỗi, hệ thống đã tiến hành trừ tiền thành công trong tài khoản của khách hàng mà khách hàng không nhận được nội dung mình đã mua hoặc không sử dụng được chính sách mà thuê bao được hưởng theo gói cước đã đăng ký, hệ thống sẽ gửi lại nội dung hoặc bù lại số ngày sử dụng cho khách hàng tương ứng với nội dung bị lỗi hoặc số ngày bị gián đoạn sử dụng.
            <h4>7. Sở hữu trí tuệ</h4>
            Mọi nội dung được đăng tải trên DV DigiLife, bao gồm thiết kế, logo, các phần mềm, chức năng kỹ thuật, các hình ảnh, cấu trúc trang đều thuộc bản quyền của DV DigiLife và đơn vị sở hữu trang web/ứng dụng này. Nghiêm cấm mọi sao chép, sửa đổi, trưng bày, phân phát, chuyển tải, tái sử dụng, xuất bản, bán, cấp phép, tái tạo hay sử dụng bất cứ nội dung nào của website/ ứng dụng cho bất kỳ mục đích nào mà không có sự xác nhận bằng văn bản của Ban quản trị DV DigiLife hoặc/và đơn vị sở hữu.
            <h4>8. Sử dụng thông tin</h4>
            Khi vào website/ ứng dụng này là bạn đã thừa nhận và đồng ý rằng mọi thông tin hay dữ liệu mà bạn chuyển đến website/ ứng dụng này dưới bất kỳ hình thức nào, vì bất kỳ lý do gì, sẽ trở thành tài sản của DV DigiLife và đơn vị sở hữu DV DigiLife. Những thông tin này sẽ được DV DigiLife và đơn vị sở hữu DV DigiLife sử dụng trong quá trình phát triển sản phẩm, phát triển thương hiệu, kinh doanh sản phẩm và các hoạt động khác. DV DigiLife được phép sử dụng miễn phí các ý tưởng hoặc nội dung thông tin do người sử dụng cung cấp. Người sử dụng không có quyền đòi hỏi bất cứ khoản tài chính nào (tiền thưởng, lệ phí, nhuận bút hoặc các hình thức chi trả khác…) liên quan đến việc sử dụng thông tin này.<br />
            Chúng tôi cam kết sẽ không chia sẻ những thông tin cá nhân (bao gồm: thông tin lý lịch, email, mật khẩu) của thành viên cho bên thứ ba nào khác mà không có sự đồng ý của thành viên đó. Người dùng DV DigiLife được phép sử dụng để chia sẻ trên mạng, với điều kiện phải ghi rõ nguồn tham khảo và chủ sở hữu thông tin.
            <h4>9. Sửa đổi</h4>
            DV DigiLife có quyền thay đổi, bổ sung, thêm hoặc bớt nội dung website/ ứng dụng cũng như các điều khoản sử dụng vào bất cứ lúc nào.
            <h4>10. Kết nối với các website/ ứng dụng khác</h4>
            Mặc dù website/ ứng dụng này có thể kết nối với các website/ ứng dụng khác, điều đó không có nghĩa là DV DigiLife trực tiếp hay gián tiếp tham gia vào việc phê chuẩn, hợp tác, tài trợ, chứng thực hay kết nạp các website/ ứng dụng đó, trừ khi được công bố chính thức. Khi truy cập vào website/ ứng dụng này là bạn đã thừa nhận và đồng ý rằng DV DigiLife không kiểm soát tất cả các website/ ứng dụng liên kết, và DV DigiLife không chịu trách nhiệm về nội dung của bất kỳ website/ ứng dụng ngoại lai nào, hay bất kỳ website/ ứng dụng nào có liên kết với website/ ứng dụng này.
            <h4>11. Quyền hạn của DV DigiLife</h4>
            Nếu phát hiện người sử dụng vi phạm các quy định đã nêu trong Bản thỏa thuận này, chúng tôi có quyền cấm hoặc từ chối truy cập của bất cứ tài khoản nào mà không cần báo trước. Trong những trường hợp nghiêm trọng, DV DigiLife sẽ phối hợp với chính quyền để truy cứu trách nhiệm đối với những người vi phạm.<br />
            Chúng tôi có quyền sử dụng thông tin tài khoản để gửi thông báo cho chủ tài khoản về các sản phẩm và dịch vụ hoặc chương trình khuyến mại thông qua thư điện tử hoặc thư bưu chính. Chúng tôi cũng có thể sử dụng các thông tin này để tiến hành các cuộc điều tra (Ví dụ: thông báo thay đổi dịch vụ trong hệ thống, thông báo về các chương trình khuyến mại hay các hành động nhân đạo và xã hội khác).<br />
            Chúng tôi có toàn quyền thay đổi những điều khoản, chính sách mà chúng tôi cho rằng không còn phù hợp trong thoả thuận này mà không cần báo trước, người sử dụng mặc nhiên đồng ý chấp hành các sửa đổi trong quy định mới.<br />
            Chúng tôi sẽ chỉ cung cấp thông tin cá nhân và/hoặc địa chỉ IP của chủ tài khoản khi được các cơ quan luật pháp Việt Nam yêu cầu và để làm các việc thực sự cần thiết như sau:<br />
            – Phối hợp với cơ quan điều tra để tìm ra những hoạt động bất hợp pháp liên quan đến việc phát tán thông tin và an ninh mạng.<br />
            – Bảo vệ quyền và tài sản liên quan đến hệ thống dịch vụ do chúng tôi cung cấp.<br />
            – Nhận dạng những người cố tình vi phạm luật thông tin và an ninh mạng
            <h4>12. Điều khoản thi hành</h4>
            Thoả thuận sử dụng này ràng buộc chúng tôi kể từ khi được đưa lên website/ ứng dụng DV DigiLife và ràng buộc Quý khách hàng kể từ khi đăng ký sử dụng dịch vụ do DV DigiLife cung cấp. Khi có sửa đổi bổ sung được đưa lên thì chúng tôi và Quý khách hàng đều phải tuân thủ những sửa đổi bổ sung do chúng tôi đưa ra.
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Term);
