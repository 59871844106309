import React from 'react';
import './style.scss';
import logo from 'assets/icons/logo.svg';
import mobile from 'assets/imgs/Banner-iphone.png';
import gg_play from 'assets/imgs/Banner_app_store.png';
import app_store from 'assets/imgs/Banner_google_play.png';

const Header = () => {
  return (
    <header className="header">
      <div className="header__logo">
        <img src={logo} alt="logo" />
      </div>

      <div className="container mt-5">
        <div className="header__title d-md-none">
          <h1>SIÊU ỨNG DỤNG</h1>
          <h1><b>ALL IN ONE</b></h1>
        </div>
      </div>

      <div className="container header__wrapper">
        <div className="header__left">
          <div className="header__title d-none d-md-block">
            <h1>SIÊU ỨNG DỤNG</h1>
            <h1><b>ALL IN ONE</b></h1>
          </div>
          
          <div className="mt-4 header__des">
            <p>Tích hợp các tiện ích mới nhất, độc đáo trong 1 siêu ứng dụng đáp ứng mọi nhu cầu cuộc sống số để bạn thoái mái trải nghiệm.</p>
            <p>Miễn phí toàn bộ data VinaPhone truy cập kho nội dung giải trí tích hợp đầu tiên tại Việt Nam</p>
          </div>

          <div className="header__store">
            <a href="https://apps.apple.com/us/app/id1516951370" target="_blank" rel="noopener noreferrer">
              <img src={gg_play} alt="gg play" className="header__gg-store" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.vnpt.media&hl=vi" target="_blank" rel="noopener noreferrer">
              <img src={app_store} alt="gg play" /></a>
          </div>
        </div>

        <div className="header__right">
          <img alt="mobile" src={mobile} className="header__mobile" />
        </div>
      </div>
    </header>
  );
}

export default Header;
